<template>
    <Modal>
        <template v-if="isLoading">
            <Placeholder element="span" :classes="['status-label']" :width="20"/>
            <h1>
                <Placeholder :width="70"/>
            </h1>
            <Placeholder element="time" :width="35"/>
            <p>
                <Placeholder :width="80"/>
            </p>
            <Placeholder element="div" :height="60" :border-radius="15" style="margin: 2.2rem 0;"/>
            <p>
                <small>
                    <Placeholder :width="20"/>
                </small><br>
                <strong class="larger">
                    <Placeholder :width="30"/>
                </strong>
            </p>
        </template>
        <template v-else>
            <span :class="`status-label ${statusColor}`">{{ statusText }}</span>
            <h1>{{ budget.title }}</h1>
            <time>{{ formatDateToFnsDate(budget.created.toDate()) }}</time>
            <p v-if="budget.description" v-html="formatText(budget.description)" class="format"></p>
            <ViewFile v-if="budget.file" v-bind="budget.file"/>

            <CardList v-if="budget.contract" class="inline">
                <Placeholder v-if="isLoadingContract" element="li" :classes="['card column-1']" :height="157"/>
                <ContractCard v-else v-bind="budget.contract" :readonly="true"/>
            </CardList>

            <p class="columns">
                <span v-if="budget.group" class="align-left">
                    <small>Gruppe</small><br>
                    <strong>{{ budget.group }}</strong>
                </span>
                <span>
                    <small>Afsat beløb <i>/ {{ getPaymentTypeLabel(budget.payment.type) }}</i></small><br>
                    <strong class="larger">{{ formatNumberToPrice(budget.payment.price + addition) }}</strong>
                </span>
            </p>
        </template>

        <template v-if="isLoadingExpenses">
            <h2>Betalinger</h2>
            <p class="columns">
                <span class="longer">
                    <small>Beskrivelse</small><br>
                    <Placeholder :width="80"/>
                </span>
                <span class="grey">
                    <small>Dato</small><br>
                    <Placeholder :width="80"/>
                </span>
                <span>
                    <small>Betalt</small><br>
                    <Placeholder :width="90"/>
                </span>
            </p>
            <p class="columns">
                <span>
                    <small>Betalt i alt</small><br>
                    <strong><Placeholder :width="20"/></strong>
                </span>
            </p>
            <p class="columns">
                <span>
                    <small>Rest til betaling</small><br>
                    <strong><Placeholder :width="20"/></strong>
                </span>
            </p>
        </template>
        <template v-if="!isLoadingExpenses && expenses.length">
            <h2>Betalinger</h2>
            <p class="columns" v-for="expense in expenses" :key="expense.id">
                <span class="longer">
                    <small>Beskrivelse</small><br>
                    <a href="javascript:void(0)" @click.prevent="onExpenseClick(expense)" class="floating-icon">
                        <Icon v-if="expense.file" name="paperclip" />
                        {{ expense.description }}
                    </a>
                </span>
                <span class="grey">
                    <small>Dato</small><br>
                    {{ formatDateToFnsDate(expense.created.toDate()) }}
                </span>
                <span>
                    <small>Betalt</small><br>
                    <strong>{{ formatNumberToPrice(expense.price) }}</strong>
                </span>
            </p>
            <p class="columns">
                <span>
                    <small>Betalt i alt</small><br>
                    <strong>{{ formatNumberToPrice(totalExpensesPrice) }}</strong>
                </span>
            </p>
            <p class="columns">
                <span>
                    <small :class="{ 'red' : budget.payment.price < totalExpensesPrice }">Rest til betaling</small><br>
                    <strong>{{ formatNumberToPrice(budget.payment.price - totalExpensesPrice) }}</strong>
                </span>
            </p>
        </template>

        <template #footer v-if="isLoading">
            <Placeholder element="button" :classes="['small secondary']" :height="42" :width="48" :border-radius="30"/>
            <Placeholder element="button" :classes="['small secondary']" :height="42" :width="48" :border-radius="30"/>
            <Placeholder element="button" :classes="['small']" :height="42" :border-radius="30"/>
        </template>
        <template #footer v-else>
            <ActionButton label="Rediger" icon="edit" :small="true" :secondary="true" @click="edit" :disabled="!subscription.isActive" :tooltip="editTooltip"/>
            <ActionButton label="Slet" icon="trash" :small="true" :secondary="true" :red="true" @click="isConfirmRemoveDialogOpen = true"
                          :tooltip="deleteTooltip" :disabled="!!expenses.length || !subscription.isActive" />
            <ActionButton v-if="!isPaid" label="Tilføj udgift" icon="plus" :small="true" @click="createExpense"
                          :disabled="disallowCreateExpense || !subscription.isActive" :tooltip="createExpenseTooltip"/>
        </template>

        <Dialog v-if="isConfirmRemoveDialogOpen">
            <template #title>Slet budgetpost</template>
            <template #default>
                <p>Du er ved at slette en budgetpost. Når først en budgetpost er slettet, kan den ikke genskabes.</p>
                <p>Er du sikker på at vil slette den alligevel?</p>
            </template>
            <template #actions>
                <ActionButton icon="trash" label="Slet alligevel" :small="true" :red="true" @click="remove"/>
                <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true" @click="isConfirmRemoveDialogOpen = false"/>
            </template>
        </Dialog>

    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { onUnmounted, reactive, toRefs } from 'vue';
import { budgetsCollection, contractsCollection, expensesCollection } from '@/utils/collections';
import Placeholder from '@/components/Placeholder.vue';
import methods from '@/utils/methods';
import ViewFile from '@/components/ViewFile.vue';
import CardList from '@/components/CardList.vue';
import ContractCard from '@/components/cards/ContractCard.vue';
import useState from '@/store';
import { useRoute } from 'vue-router';
import Icon from '@/components/Icon.vue';
import Dialog from '@/components/Dialog.vue';

export default {
    name: 'Budget',
    components: {
        Modal,
        ActionButton,
        Placeholder,
        ViewFile,
        CardList,
        ContractCard,
        Icon,
        Dialog,
    },
    mixins: [methods],
    setup() {
        const { modalData, setModalData, subscription } = useState();
        const hasLocalData = Object.entries(modalData.value).length;
        const route = useRoute();
        const data = reactive({
            budget: {
                id: route.params.id,
                ...modalData.value,
            },
            isLoading: !hasLocalData,
            isLoadingContract: true,
            expenses: [],
            totalExpensesPrice: 0,
            isLoadingExpenses: true,
            isConfirmRemoveDialogOpen: false,
            addition: modalData.value.contract?.addition || 0,
        });

        async function getContractFromBudget() {
            try {
                const snapshot = await contractsCollection().doc(data.budget.contract.ref.id).get();
                const contract = snapshot.data();
                if (contract) data.budget.contract = { id: snapshot.id, ...contract };
                else delete data.budget.contract;
                data.isLoadingContract = false;
            } catch (err) {
                data.isLoadingContract = false;
            }
        }

        if (!data.isLoading && data.budget.contract) getContractFromBudget();

        if (data.isLoading) {
            budgetsCollection().doc(data.budget.id).get().then((snapshot) => {
                data.budget = { id: snapshot.id, ...snapshot.data() };
                data.isLoading = false;
                if (data.budget.contract) getContractFromBudget();
            });
        }

        const unsubscribe = expensesCollection(data.budget.id).onSnapshot((querySnapshot) => {
            data.expenses = querySnapshot.docs.map((snapshot) => ({
                id: snapshot.id,
                ...snapshot.data(),
            }));

            data.totalExpensesPrice = data.expenses.reduce((acc, expense) => acc + expense.price, 0);
            data.isLoadingExpenses = false;
        }, (err) => {
            console.log(err);
        });

        onUnmounted(() => {
            unsubscribe();
        });

        return { setModalData, ...toRefs(data), subscription };
    },
    computed: {
        statusColor() {
            return this.isPaid ? 'green' : 'yellow';
        },
        statusText() {
            const percent = Math.round((this.budget.payment.paid / this.budget.payment.price) * 100);
            return `Betalt ${ percent }%`;
        },
        isPaid() {
            return this.budget.payment.price <= this.budget.payment.paid;
        },
        disallowCreateExpense() {
            return this.budget.contract && this.budget.contract?.status?.state !== 'ACCEPTED';
        },
        createExpenseTooltip() {
            if (!this.subscription.isActive) return 'Tilknyt et abonnement til din konto, for at tilføje udgift';
            return this.disallowCreateExpense ? 'Tilknyttede kontrakt skal være accepteret' : null;
        },
        deleteTooltip() {
            if (!this.subscription.isActive) return 'Tilknyt et abonnement til din konto, for at slette budgetpost';
            return this.expenses.length ? 'Slet alle udgifter først' : null;
        },
        editTooltip() {
            return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at rediger budgetpost' : null;
        },
    },
    methods: {
        async remove() {
            try {
                await budgetsCollection().doc(this.budget.id).delete();
                await this.$router.push({ name: 'budget' });
            } catch (err) {
                console.log(err);
            }
        },
        createExpense() {
            this.$router.push({ name: 'create-expense', params: { budgetPost: this.budget.id } });
        },
        edit() {
            this.$router.push({ name: 'edit-budget' });
        },
        onExpenseClick(expense) {
            this.setModalData(expense);
            this.$router.push({ name: 'view-expense', params: { budget: this.budget.id, id: expense.id } });
        },
    },
};
</script>
